<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <number-value :value="PiCO" unit="\text{atm}" />
        of
        <chemical-latex content="CO(g)" />
        and
        <number-value :value="PiH2O" unit="\text{atm}" />
        of
        <chemical-latex content="H2O(g)" />
        were added to an otherwise empty vessel. The reaction shown below was left until it reached
        equilibrium. Note that the equilibrium constant,
        <stemble-latex content="$\text{K}_{\text{p}},$" />
        at
        <stemble-latex content="$150^\circ\text{C}$" />
        is
        <number-value :value="Kp" />.
      </p>

      <p class="mb-5 pl-10">
        <chemical-latex content="CO(g) + H2O(g) <=> CO2(g) + H2(g)" />
      </p>

      <p class="mb-0">
        a) Based on the information provided above, fill in the ICE table below inputting
        <stemble-latex content="$\mathrm{x}$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should contain the
        <stemble-latex content="$\mathrm{x}$" /> variable.
      </p>

      <v-simple-table class="mb-4" style="width: 525px">
        <thead>
          <th style="vertical-align: middle; text-align: center; height: 40px" />
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <chemical-latex content="CO(g)" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <stemble-latex content="$+$" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <chemical-latex content="H2O(g)" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <chemical-latex content="<=>" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <chemical-latex content="CO2(g)" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <stemble-latex content="$+$" />
          </th>
          <th style="vertical-align: middle; text-align: center; height: 40px">
            <chemical-latex content="H2(g)" />
          </th>
          <tr>
            <td style="vertical-align: middle; height: 40px; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialCO" dense class="text-center" style="width: 62px" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.initialH2O"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.initialCO2"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.initialH2"
                dense
                group-size="sm"
                class="text-center"
                style="width: 62px"
              />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 40px; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeCO" dense class="text-center" style="width: 62px" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeH2O" dense class="text-center" style="width: 62px" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeCO2" dense class="text-center" style="width: 62px" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeH2" dense class="text-center" style="width: 62px" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 40px; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.equilibriumCO"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.equilibriumH2O"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.equilibriumCO2"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input
                v-model="inputs.equilibriumH2"
                dense
                class="text-center"
                style="width: 62px"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        b) Determine the value of
        <stemble-latex content="$\text{x}$" />
        from the previous question.
      </p>

      <calculation-input
        v-model="inputs.valueOfx"
        class="mb-6"
        prepend-text="$\text{x}:$"
        append-text="$\mathrm{atm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) Determine the partial pressures of each of the reactants and products when the system
        reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.pCO"
        class="mb-4"
        style="width: 200px"
        prepend-text="$\text{P}_{\ce{CO}}:$"
        append-text="$\mathrm{atm}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.pH2O"
        class="mb-4"
        style="width: 200px"
        prepend-text="$\text{P}_{\ce{H2O}}:$"
        append-text="$\mathrm{atm}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.pCO2"
        class="mb-4"
        style="width: 200px"
        prepend-text="$\text{P}_{\ce{CO2}}:$"
        append-text="$\mathrm{atm}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.pH2"
        class="mb-6"
        style="width: 200px"
        prepend-text="$\text{P}_{\ce{H2}}\,\,\,:$"
        append-text="$\mathrm{atm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        d) From the information provided in the question, determine the value of
        <stemble-latex content="$\Delta\text{G}^\circ$" /> (in kJ/mol) for the reaction at
        <stemble-latex content="$150^\circ\text{C.}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-4"
        prepend-text="$\Delta\text{G}^\circ :$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question300',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        initialCO: null,
        initialH2O: null,
        initialCO2: null,
        initialH2: null,
        changeCO: null,
        changeH2O: null,
        changeCO2: null,
        changeH2: null,
        equilibriumCO: null,
        equilibriumH2O: null,
        equilibriumCO2: null,
        equilibriumH2: null,
        valueOfx: null,
        pCO: null,
        pH2O: null,
        pCO2: null,
        pH2: null,
        deltaG: null,
      },
    };
  },
  computed: {
    PiCO() {
      return this.taskState.getValueBySymbol('PiCO').content;
    },
    PiH2O() {
      return this.taskState.getValueBySymbol('PiH2O').content;
    },
    Kp() {
      return this.taskState.getValueBySymbol('Kp').content;
    },
  },
};
</script>
